(function($) {
  // Toggle function for offcanvas navigation
  $( "#nav-toggle, .exit-off-canvas" ).click(function() {
      $( ".off-canvas-wrap" ).toggleClass( "move-left" );
  });


  //Add Toggle Icons to Offcanvas for Dropdowns
  var parentItem = $('.right-off-canvas-menu .menu-item-has-children');
  $(parentItem).each(function(){
      $(this).children('a').after('<div class="toggle"><i class="icon-right-open" aria-hidden="true"></i></div>');
  });

  // Control the open/clos state of submenus depending on current or active menu-items
  $('.right-off-canvas-menu .current-menu-ancestor').addClass('open');
  $('.right-off-canvas-menu .current-menu-parent').addClass('open');
  $('.right-off-canvas-menu .menu-item-has-children.current-menu-item').addClass('open');

  // Prevents the Submenus from stayin' opened after page reloads like it does on phones
  $('.right-off-canvas-menu .menu-item-has-children > .toggle').on('click', function () {
      var element = $(this).parent('li');
      if (element.hasClass('open')) {
          element.removeClass('open');
      }
      else {
          element.addClass('open');
      }
  });

  $('.right-off-canvas-menu .menu-item a').click(function() {
    $( ".off-canvas-wrap" ).toggleClass( "move-left" );
  });
})(jQuery);
